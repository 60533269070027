import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "block mb-2"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueInputText = _resolveComponent("PrimeVueInputText")!
  const _component_FieldErrors = _resolveComponent("FieldErrors")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.wrapperClass)
    }, [
      _renderSlot(_ctx.$slots, "prepend"),
      _createVNode(_component_PrimeVueInputText, {
        "model-value": _ctx.modelValue,
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
      }, null, 8, ["model-value", "disabled", "placeholder"]),
      _renderSlot(_ctx.$slots, "append")
    ], 2),
    (_ctx.help)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: "block mt-1",
          innerHTML: _ctx.help
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.name)
      ? (_openBlock(), _createBlock(_component_FieldErrors, {
          key: 2,
          "errors-map": _ctx.errorsMap,
          name: _ctx.name
        }, null, 8, ["errors-map", "name"]))
      : _createCommentVNode("", true)
  ]))
}
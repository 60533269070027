
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import PrimeVueTextarea from 'primevue/textarea';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  components: {
    PrimeVueTextarea,
  },
  props: {
    modelValue: {
      type: String,
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    help: {
      type: String,
    },
    rows: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
});
